export const DOCUMENT_PICTURE = "DOCUMENT_PICTURE"
export const DOCUMENT_PICTURE_SUCCESS = "DOCUMENT_PICTURE_SUCCESS"
export const DOCUMENT_PICTURE_FAIL = "DOCUMENT_PICTURE_FAIL"

export const DOCUMENT_PREVIEW = "DOCUMENT_PREVIEW"
export const DOCUMENT_PREVIEW_SUCCESS = "DOCUMENT_PREVIEW_SUCCESS"
export const DOCUMENT_PREVIEW_FAIL = "DOCUMENT_PREVIEW_FAIL"

export const DELETE_DOCUMENT_KYC = "DELETE_DOCUMENT_KYC"
export const DELETE_DOCUMENT_KYC_SUCCESS = "DELETE_DOCUMENT_KYC_SUCCESS"
export const DELETE_DOCUMENT_KYC_FAIL = "DELETE_DOCUMENT_KYC_FAIL"

export const UPLOAD_DOCUMENT_PICTURE = "UPLOAD_DOCUMENT_PICTURE"
export const UPLOAD_DOCUMENT_PICTURE_SUCCESS = "UPLOAD_DOCUMENT_PICTURE_SUCCESS"
export const UPLOAD_DOCUMENT_PICTURE_FAIL = "UPLOAD_DOCUMENT_PICTURE_FAIL"

export const UPLOAD_DOCUMENT_PICTURE_URL = "UPLOAD_DOCUMENT_PICTURE_URL"
export const UPLOAD_DOCUMENT_PICTURE_SUCCESS_URL =
  "UPLOAD_DOCUMENT_PICTURE_SUCCESS_URL"
export const UPLOAD_DOCUMENT_PICTURE_FAIL_URL =
  "UPLOAD_DOCUMENT_PICTURE_FAIL_URL"

export const DOWNLOAD_IMAGE = "DOWNLOAD_IMAGE"

export const PREVIEW_IMAGE_RESET = "PREVIEW_IMAGE_RESET"
