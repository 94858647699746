export const GET_LEARNER_DETAILS = "GET_LEARNER_DETAILS"
export const GET_LEARNER_DETAILS_SUCCESS = "GET_LEARNER_DETAILS_SUCCESS"
export const GET_LEARNER_DETAILS_FAIL = "GET_LEARNER_DETAILS_FAIL"

export const GET_LEARNER_DETAILS_COUNT_SUCCESS =
  "GET_LEARNER_DETAILS_COUNT_SUCCESS"
export const GET_LEARNER_DETAILS_COUNT_FAIL = "GET_LEARNER_DETAILS_COUNT_FAIL"

export const PROFILE_PICTURE = "PROFILE_PICTURE"
export const PROFILE_PICTURE_SUCCESS = "PROFILE_PICTURE_SUCCESS"
export const PROFILE_PICTURE_FAIL = "PROFILE_PICTURE_FAIL"

export const DELETE_PROFILE_PICTURE = "DELETE_PROFILE_PICTURE"
export const DELETE_PROFILE_PICTURE_SUCCESS = "DELETE_PROFILE_PICTURE_SUCCESS"
export const DELETE_PROFILE_PICTURE_FAIL = "DELETE_PROFILE_PICTURE_FAIL"

export const UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE"
export const UPLOAD_PROFILE_PICTURE_SUCCESS = "UPLOAD_PROFILE_PICTURE_SUCCESS"
export const UPLOAD_PROFILE_PICTURE_FAIL = "UPLOAD_PROFILE_PICTURE_FAIL"

export const UPLOAD_PROFILE_PICTURE_URL = "UPLOAD_PROFILE_PICTURE_URL"
export const UPLOAD_PROFILE_PICTURE_SUCCESS_URL =
  "UPLOAD_PROFILE_PICTURE_SUCCESS_URL"
export const UPLOAD_PROFILE_PICTURE_FAIL_URL = "UPLOAD_PROFILE_PICTURE_FAIL_URL"

export const EDIT_LEARNER_DETAIL = "EDIT_LEARNER_DETAIL"
export const EDIT_LEARNER_DETAIL_SUCCESS = "EDIT_LEARNER_DETAIL_SUCCESS"
export const EDIT_LEARNER_DETAIL_FAIL = "EDIT_LEARNER_DETAIL_FAIL"
