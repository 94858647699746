import React from "react"
import LearnerPage from "./LearnerDetails/LearnerPage"

const Learner = () => {
  return (
    <>
      <LearnerPage />
    </>
  )
}

export default Learner
