export const GET_COURSES = "GET_COURSES"
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS"
export const GET_COURSES_FAIL = "GET_COURSES_FAIL"

export const GET_COURSES_COUNT_SUCCESS = "GET_COURSES_COUNT_SUCCESS"
export const GET_COURSES_COUNT_FAIL = "GET_COURSES_COUNT_FAIL"

export const DELETE_LEARNER = "DELETE_LEARNER"
export const DELETE_LEARNER_SUCCESS = "DELETE_LEARNER_SUCCESS"
export const DELETE_LEARNER_FAIL = "DELETE_LEARNER_FAIL"

export const FILTER_STATUS_LEARNER = "FILTER_STATUS_LEARNER"
