export const GET_LEARNER = "GET_LEARNER"
export const GET_LEARNER_SUCCESS = "GET_LEARNER_SUCCESS"
export const GET_LEARNER_FAIL = "GET_LEARNER_FAIL"

export const GET_LEARNER_COUNT_SUCCESS = "GET_LEARNER_COUNT_SUCCESS"
export const GET_LEARNER_COUNT_FAIL = "GET_LEARNER_COUNT_FAIL"

export const GET_ALL_LEARNER = "GET_ALL_LEARNER"
export const GET_ALL_LEARNER_SUCCESS = "GET_ALL_LEARNER_SUCCESS"
export const GET_ALL_LEARNER_FAIL = "GET_ALL_LEARNER_FAIL"

export const DELETE_LEARNER = "DELETE_LEARNER"
export const DELETE_LEARNER_SUCCESS = "DELETE_LEARNER_SUCCESS"
export const DELETE_LEARNER_FAIL = "DELETE_LEARNER_FAIL"

export const FILTER_STATUS_LEARNER = "FILTER_STATUS_LEARNER"
