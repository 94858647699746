export const GET_BATCHES = "GET_BATCHES"
export const GET_BATCHES_SUCCESS = "GET_BATCHES_SUCCESS"
export const GET_BATCHES_FAIL = "GET_BATCHES_FAIL"

export const GET_BATCHES_COUNT_SUCCESS = "GET_BATCHES_COUNT_SUCCESS"
export const GET_BATCHES_COUNT_FAIL = "GET_BATCHES_COUNT_FAIL"

// MAIN BATCHES

export const GET_BATCHES_LIST = "GET_BATCHES_LIST"
export const GET_BATCHES_LIST_SUCCESS = "GET_BATCHES_LIST_SUCCESS"
export const GET_BATCHES_LIST_FAIL = "GET_BATCHES_LIST_FAIL"

export const GET_BATCHES_LIST_COUNT_SUCCESS = "GET_BATCHES_LIST_COUNT_SUCCESS"
export const GET_BATCHES_LIST_COUNT_FAIL = "GET_BATCHES_LIST_COUNT_FAIL"

// LEARNER

export const GET_BATCHES_LEARNER = "GET_BATCHES_LEARNER"
export const GET_BATCHES_LEARNER_SUCCESS = "GET_BATCHES_LEARNER_SUCCESS"
export const GET_BATCHES_LEARNER_FAIL = "GET_BATCHES_LEARNER_FAIL"

export const GET_BATCHES_LEARNER_COUNT_SUCCESS =
  "GET_BATCHES_LEARNER_COUNT_SUCCESS"
export const GET_BATCHES_LEARNER_COUNT_FAIL = "GET_BATCHES_LEARNER_COUNT_FAIL"

// GRADE BOOK

export const GET_GRADE_BOOK = "GET_GRADE_BOOK"
export const GET_GRADE_BOOK_SUCCESS = "GET_GRADE_BOOK_SUCCESS"
export const GET_GRADE_BOOK_FAIL = "GET_GRADE_BOOK_FAIL"

export const GET_GRADE_BOOK_COUNT_SUCCESS = "GET_GRADE_BOOK_COUNT_SUCCESS"
export const GET_GRADE_BOOK_COUNT_FAIL = "GET_GRADE_BOOK_COUNT_FAIL"

// GET EDIT BATCH

export const GET_BATCH_API = "GET_BATCH_API"
export const GET_BATCH_API_SUCCESS = "GET_BATCH_API_SUCCESS"
export const GET_BATCH_API_FAIL = "GET_BATCH_API_FAIL"

export const GET_BATCH_API_COUNT_SUCCESS = "GET_BATCH_API_COUNT_SUCCESS"
export const GET_BATCH_API_COUNT_FAIL = "GET_BATCH_API_COUNT_FAIL"

// CREATE NEW BATCHES

export const GET_NEW_BATCHES = "GET_NEW_BATCHES"
export const GET_NEW_BATCHES_SUCCESS = "GET_NEW_BATCHES_SUCCESS"
export const GET_NEW_BATCHES_FAIL = "GET_NEW_BATCHES_FAIL"

export const GET_NEW_BATCHES_COUNT_SUCCESS = "GET_NEW_BATCHES_COUNT_SUCCESS"
export const GET_NEW_BATCHES_COUNT_FAIL = "GET_NEW_BATCHES_COUNT_FAIL"

// EDIT NEW BATCHES

export const EDIT_NEW_BATCH = "EDIT_NEW_BATCH"
export const EDIT_NEW_BATCH_SUCCESS = "EDIT_NEW_BATCH_SUCCESS"
export const EDIT_NEW_BATCH_FAIL = "EDIT_NEW_BATCH_FAIL"

//CREATE NEW BATCHES
export const CREATE_NEW_BATCH = "CREATE_NEW_BATCH"
export const CREATE_NEW_BATCH_SUCCESS = "CREATE_NEW_BATCH_SUCCESS"
export const CREATE_NEW_BATCH_FAIL = "CREATE_NEW_BATCH_FAIL"

// DASHBOARD

export const GET_DASHBOARD = "GET_DASHBOARD"
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS"
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL"

export const GET_DASHBOARD_COUNT_SUCCESS = "GET_DASHBOARD_COUNT_SUCCESS"
export const GET_DASHBOARD_COUNT_FAIL = "GET_DASHBOARD_COUNT_FAIL"

// MENTOR API
export const GET_MENTOR = "GET_MENTOR"
export const GET_MENTOR_SUCCESS = "GET_MENTOR_SUCCESS"
export const GET_MENTOR_FAIL = "GET_MENTOR_FAIL"

export const GET_MENTOR_COUNT_SUCCESS = "GET_MENTOR_COUNT_SUCCESS"
export const GET_MENTOR_COUNT_FAIL = "GET_MENTOR_COUNT_FAIL"

// LECTURE API
export const GET_LECTURE = "GET_LECTURE"
export const GET_LECTURE_SUCCESS = "GET_LECTURE_SUCCESS"
export const GET_LECTURE_FAIL = "GET_LECTURE_FAIL"

export const GET_LECTURE_COUNT_SUCCESS = "GET_LECTURE_COUNT_SUCCESS"
export const GET_LECTURE_COUNT_FAIL = "GET_LECTURE_COUNT_FAIL"

// DELETE API
export const DELETE_BATCHES = "DELETE_BATCHES"
export const DELETE_BATCHES_SUCCESS = "DELETE_BATCHES_SUCCESS"
export const DELETE_BATCHES_FAIL = "DELETE_BATCHES_FAIL"

export const FILTER_STATUS_LEARNER = "FILTER_STATUS_LEARNER"

export const GET_ALL_BATCHES_LIST = "GET_ALL_BATCHES_LIST"
export const GET_ALL_BATCHES_LIST_SUCCESS = "GET_ALL_BATCHES_LIST_SUCCESS"
export const GET_ALL_BATCHES_LIST_FAIL = "GET_ALL_BATCHES_LIST_FAIL"
