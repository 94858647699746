export const GET_VARIANT = "GET_VARIANT"
export const GET_VARIANT_SUCCESS = "GET_VARIANT_SUCCESS"
export const GET_VARIANT_FAIL = "GET_VARIANT_FAIL"

export const GET_VARIANT_COUNT_SUCCESS = "GET_VARIANT_COUNT_SUCCESS"
export const GET_VARIANT_COUNT_FAIL = "GET_VARIANT_COUNT_FAIL"

export const EDIT_VARIANT = "EDIT_VARIANT"
export const EDIT_VARIANT_SUCCESS = "EDIT_VARIANT_SUCCESS"
export const EDIT_VARIANT_FAIL = "EDIT_VARIANT_FAIL"

export const DELETE_LEARNER = "DELETE_LEARNER"
export const DELETE_LEARNER_SUCCESS = "DELETE_LEARNER_SUCCESS"
export const DELETE_LEARNER_FAIL = "DELETE_LEARNER_FAIL"

export const FILTER_STATUS_LEARNER = "FILTER_STATUS_LEARNER"
