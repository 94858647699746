export const GET_CURRICULUM = "GET_CURRICULUM"
export const GET_CURRICULUM_SUCCESS = "GET_CURRICULUM_SUCCESS"
export const GET_CURRICULUM_FAIL = "GET_CURRICULUM_FAIL"

export const GET_CURRICULUM_COUNT_SUCCESS = "GET_CURRICULUM_COUNT_SUCCESS"
export const GET_CURRICULUM_COUNT_FAIL = "GET_CURRICULUM_COUNT_FAIL"

export const DELETE_LEARNER = "DELETE_LEARNER"
export const DELETE_LEARNER_SUCCESS = "DELETE_LEARNER_SUCCESS"
export const DELETE_LEARNER_FAIL = "DELETE_LEARNER_FAIL"

export const FILTER_STATUS_LEARNER = "FILTER_STATUS_LEARNER"
